.termlink {
  display: inline;
  word-break: break-word;
  -webkit-tap-highlight-color: transparent;
  color: currentColor;
}

.termlink:focus {
  outline: 1px solid currentColor;
}
