.nav {
  width: 100%;
}

.list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  padding: 0;
  z-index: 1;
  list-style-type: none;
  background-color: #f5f5f5;

  &.subList {
    position: relative;
  }
}

.item {
  padding: 0.25rem 1rem;
  margin-bottom: 1rem;

  a {
    color: inherit;
    text-decoration: inherit;
  }

  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }

  &:active {
    background-color: #ccc;
  }

  &.subItem {
    padding-left: 1.5rem !important;
  }
}

.itemWithSub {
  padding: 0.25rem 1rem;
  margin-bottom: 1rem;

  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 0.5rem;
  }
}

.icon {
  margin-right: 0.5rem;
}

.hide {
  display: none;
}

.activeRoute {
  border-radius: 0.5rem;
}

.selectedRoute {
  @extend .activeRoute;
}

.disabled {
  opacity: 0.5;
}

.disabled:hover {
  background-color: unset;
  cursor: default;
}

.selectedRoute.disabled {
  @extend .activeRoute;
}

.bpMediumUp {
  .navigation {
    display: block;
  }

  .list {
    position: relative;
    transform: none;
    width: auto;
    top: auto;
  }

  .item {
    padding: 0.25rem 0;
    margin-bottom: 0.5rem;

    &:hover {
      @extend .activeRoute;
    }

    &:active {
      @extend .activeRoute;
    }

    &.subItem {
      &:hover {
        padding-left: 2rem !important;
      }

      &:active {
        padding-left: 2rem !important;
      }

      &.selectedRoute {
        padding-left: 2rem !important;
      }
    }
  }

  .itemWithSub {
    padding: 0.25rem 0;
    margin-bottom: 0.5rem;

    &:hover {
      margin-left: -0.5rem;
      padding-left: 0.5rem;
    }
  }

  .activeRoute {
    margin-left: -0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
