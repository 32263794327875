.borderRight {
  border-right: 1px solid #f0f0f0;
  margin-right: -1.5rem;
  padding-right: 1.5rem;
}

.skeletonContainer {
  position: relative;
  height: 48px;
}
