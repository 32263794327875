.customInputDiv {
  animation-duration: 200ms;
  animation-name: slide-down;
  animation-iteration-count: 1;
  margin-top: 16px;
}

.customInputDiv div div div div {
  animation-duration: 200ms;
  animation-name: slide-down-input;
  animation-iteration-count: 1;
}

.customInputDiv div div div label {
  animation-duration: 200ms;
  animation-name: slide-down-input-label;
  animation-iteration-count: 1;
}

.customInputDivClose {
  animation-duration: 200ms;
  animation-name: slide-up;
  animation-iteration-count: 1;
}

@keyframes slide-down {
  0% {
    height: 0;
  }

  100% {
    height: 64px;
  }
}

@keyframes slide-down-input {
  0% {
    top: -20px;
    transform: scaleY(0.2);
  }

  100% {
    top: 0;
    transform: scaleY(1);
  }
}

@keyframes slide-down-input-label {
  0% {
    font-size: 0;
  }

  100% {
    font-size: 100%;
  }
}

@keyframes slide-up {
  0% {
    height: 64px;
  }

  100% {
    height: 0;
  }
}
