.threeDSWrapper {
  overflow: hidden;
  width: auto;
  border-radius: 1rem;
}

.threeDSIframe {
  border: 0;
  width: 100%;
  height: 660px;
  max-height: 100vh;
}

.close {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 999;
}
