.heading {
  display: block;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.container {
  display: flex;
  flex-direction: column;
}

.contentSkeletonContainer {
  position: relative;
  max-width: max-content;
}
