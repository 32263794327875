.wrapper {
  margin-bottom: 16px;
}

.noMargin {
  margin-top: 0;
  margin-bottom: 0;
}

.center {
  text-align: center;
  display: block;
}
