
@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  font-size: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F5F5F5;
}

html,
body,
#__next {
  /* Known to cause issues on some mobile browsers, will tweak if it does */
  height: 100vh;
}

* {
  box-sizing: border-box;
}
