.headerLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentHeadingContainer {
  margin-bottom: 1rem;
}

.contentAmountContainer {
  position: relative;
  max-width: max-content;
  margin-bottom: 1rem;
}

.contentAmountUnderline {
  position: absolute;
  width: 155px;
  height: 11px;
  left: 3px;
  top: 33px;
  background-color: #fff;
  z-index: -1;
}

.contentText1Container {
  margin-bottom: 0.25rem;
}

.contentText2Container {
  margin-bottom: 1rem;
}
