.profileCard {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  align-items: center;
}

.variableColumnWrapper {
  overflow: hidden;
}

.variableColumn {
  overflow: auto;
  scrollbar-width: thin;
  display: flex;
  justify-content: space-between;
}

.editWrapper {
  justify-self: end;
}
