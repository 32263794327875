.container {
  margin-left: -16px;
  margin-right: -16px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 8px;
}

.tableRow {
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
}

tbody .tableRow:hover {
  background: rgba(245, 245, 245, 0.4);
}

.headerCell {
  padding: 8px 20px 24px 20px;
  text-align: left;
}

.dataCell {
  text-align: left;
  padding: 0;
  margin: 0;
}

.linkDataCell {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  padding: 1.25rem;
  display: flex;
}

.paddingNone {
  padding: 0;
}

thead .tableRow::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 24px;
  right: 24px;
  border-bottom: 1px solid #f0f0f0;
}

.isBpLargeUp .container {
  margin-left: -24px;
  margin-right: -24px;
}
