.detailContainer {
  margin-top: 1rem;
}

.textCenter {
  text-align: center;
}

.my8 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb8 {
  margin-bottom: 1rem;
}

.mt4 {
  margin-top: 2rem;
}

.mt16 {
  margin-top: 2rem;
}

.mb16 {
 margin-bottom: 2rem;
}

.wFull {
  width: 100%;
}

.menu {
  position: absolute;
  right: 0;
  z-index: 9;
  white-space: nowrap;
}

.relative {
  position: relative;
}

.capitalize {
  text-transform: capitalize;
}

.divider:last-child {
  visibility: hidden;
  height: 0 !important;
  border-top-width: 0 !important;
}
