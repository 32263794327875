.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.contentContainer {
  display: flex;
  flex-direction: column;

  .textGroupContainer:not(:last-child) {
    margin-right: 78px;
  }
}

.textGroupContainer {
  display: flex;
  flex-direction: column;
}

.contentSkeletonContainer {
  position: relative;
  max-width: max-content;
}

.rightTextGroupContainer {
  display: flex;
  margin-top: 24px;
}

.imageContainer {
  display: none;
}

.headContainer {
  display: block;
  margin-top: -1rem;
}

.headContentContainer {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-top: 1rem;
}

.bpLargeUp {
  .contentContainer {
    flex-direction: row;
    margin-bottom: 0;

    .textGroupContainer:not(:last-child) {
      margin-right: 84px;
    }
  }

  .headContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .rightTextGroupContainer {
    margin-top: 0;
    margin-bottom: 0;
  }

  .imageContainer {
    display: block;
    margin: -1rem -2rem -1rem 0;
  }
}
