.steps {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.divider {
  display: flex;
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background-color: #f0f0f0;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.contentSkeletonContainer {
  position: relative;
  max-width: 100%;
}

.imageContainer {
  display: none;
}

.headContainer {
  display: flex;
  justify-content: space-between;
}

.bpLargeUp {
  .imageContainer {
    display: block;
    margin: -1rem -2rem -1rem 0;
  }

  .steps {
    flex-direction: initial;
  }

  .divider {
    width: 1px;
    height: auto;
    margin: 0 24px;
  }
}
