.iframeWrapper {
  border: 0;
  width: 100%;
  height: 660px;
  border-radius: 1rem;
  background-color: transparent;
}

.iframe {
  border: 0;
  width: 100%;
  height: 660px;
}
