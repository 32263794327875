.merchantTile {
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;

  &.themeUnicorn {
    border-radius: 50%;
  }
}
