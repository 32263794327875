.icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.logo {
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border: 1px solid #ccc;
  background: #f5f5f5;
  border-radius: 100%;

  svg {
    margin-top: 15px;
    margin-left: 15px;
    width: 16px;
    height: 16px;
  }
}
