.stores {
  border-radius: 20px;
  background-color: #fff;
}

.carouselControls {
  position: absolute;
  left: 0;
  top: 43%;
  width: 100%;
}

.loading{
  position: relative;
  height: 50px;
}

.bgImg{
  width: 100%;
  height: 100%;
}

.logoImg{
  width: 100%;
}

.logoText{
  color: #000;
}

/* style for OfferModal */
.offerModal{
  position: relative;
}
.offerDetail, .offerTerms{
  margin: 8px 0;
}
.offerTerms{
  margin-bottom: 24px;
}
.offerLogo{
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > img {
    max-width: 4rem;
  }
}

// style for EmptyCardSkeleton
.cardEmpty{
  display: inline-block;
  width: 100%;
  max-width: 343px;
  .content{
    position: relative;
    padding-top: 133.3334%;
    width: 100%;
  }
  .footer{
    min-height: 24px;
    margin-top: 24px;
    position: relative;
  }
}
