.container {
  display: flex;
  padding: 0;
  margin: 0 auto;
  justify-content: center;
}

.pageItem {
  list-style: none;
  height: 32px;
  width: 32px;
  background-color: white;
  border-radius: 12px;
  text-align: center;
  vertical-align: middle;
  line-height: 32px;

  &:hover {
    border: 1px solid #000;
  }
}

.pageLink {
  font-family: 'Italianplate Bold', Arial, Helvetica, sans-serif;
  cursor: pointer;
  padding: 4px;
  margin: -4px;
}

.bookend {
  text-align: center;
  vertical-align: middle;
  line-height: 32px;
  list-style: none;
  height: 32px;
  width: 32px;
}

.bookendLink {
  cursor: pointer;
}

.pageItem,
.bookend {
  margin-left: 4px;

  &:first-child {
    margin-left: 0;
  }
}
