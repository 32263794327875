.step {
  display: flex;
  flex: 1;
}

.badge {
  margin-right: 16px;
  min-width: 24px;
  height: 24px;
  color: white;
  border-radius: 50%;
  padding: 1px 0 0 0;
  text-align: center;
}

.task {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.cta {
  margin-left: 16px;
}
