.pointer-events-none {
  pointer-events: none;
}

.layout {
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header header'
    'content content'
    'footer footer';
}

.profile,
.logo,
.navigation,
.logout,
.footer {
  display: flex;
  align-items: center;
}

.logo {
  grid-area: logo;
  justify-content: center;
}

.accountBar {
  grid-area: accountBar;
}

.toast {
  grid-area: header;
  position: sticky;
  top: 16px;
  z-index: 2;
}

.header {
  grid-area: header;
  background-color: #f5f5f5;
}

.innerHead {
  display: grid;
  grid-template-areas: 'navigation logo profile';
  position: relative;
  z-index: 1;
  margin-left: -16px;
  margin-right: -16px;
  padding: 24px 16px;
  background-color: #f5f5f5;
}

.content {
  grid-area: content;
  max-width: 100%;
  min-width: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.profile {
  grid-area: profile;
  justify-content: flex-end;
}

.navigation {
  grid-area: navigation;
}

.logout {
  cursor: pointer;
  grid-area: logout;
  position: sticky;
  bottom: 0;
}

.footer {
  justify-content: center;
  grid-area: footer;
}

.bpMediumUp {
  padding-left: 16px;
  padding-right: 16px;

  &.layout {
    column-gap: 40px;
    grid-template-columns: 192px auto;
    grid-template-areas:
      'header accountBar'
      'header content'
      'logout footer';
  }

  .header {
    grid-template-areas:
      'logo'
      'navigation';
    grid-template-rows: max-content max-content;
    background-color: transparent;
  }

  .innerHead {
    display: block;
    position: sticky;
    top: 0;
    padding: 0;
    margin: 0;
  }

  .toast {
    grid-area: accountBar;
  }

  .logo,
  .accountBar,
  .navigation {
    padding: 16px 0;
  }

  .navigation {
    padding: 4px 0;
    align-items: flex-start;
  }

  .footer {
    justify-content: flex-start;
  }

  .logo {
    justify-content: flex-start;
  }
}
