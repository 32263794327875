.header {
  padding: 16px 0;
  display: flex;
}

.title {
  padding-bottom: 24px;
}

.icon {
  background-color: white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.icon span {
  margin-top: 2px;
}

.isBpSmall {
  .icon {
    height: 24px;
    width: 24px;
  }
}
