.dropDownList {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}

.modalHeader {
  text-align: center;
}

.loader {
  padding: 160px 160px;
}

.skeleton {
  height: 32px;
  width: 100%;
}
