.headInfo {
  margin-right: 48px;
}

.isBpLargeUp {
  .headInfo {
    margin-right: 72px;
  }
}

.icon {
  margin: auto;
  padding-left: 16px;
}

.orderListMobile {
  margin: -12px 0;
  padding: 0;
}

.orderListItem {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.orderDetail {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 2.875rem;
  justify-content: space-between;
}

.divider {
  background-color: #f0f0f0;
  height: 1px;
  margin: 16px 0;
}

.paginationContainer {
  margin: 0 auto;
}

.linkDataCell {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 12px 0;
}

.merchantTile {
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 2.75rem;
  height: 2.75rem;
  display: flex;
  position: relative;

  &.themeUnicorn {
    border-radius: 50%;
  }
}
