.footer {
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;

  > * {
    padding: 0 8px;
  }
}

.center {
  justify-content: center;
}
