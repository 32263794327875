.avatar {
  all: unset;
  position: relative;
}

.overlay {
  .backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    &.showBackdrop {
      display: block;
    }
  }

  .container {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff;
    z-index: 1001;
    border-radius: 0.5rem;
    padding: 2rem 0 0.5rem 0;
    max-width: 18rem;

    .profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;
      text-align: center;
      padding: 0 1rem 0 1rem;

      .image {
        margin-bottom: 1rem;
      }

      .name {
        margin-bottom: 0.25rem;
      }
    }

    .navigation {
      > ul {
        list-style: none;
        padding: 0;
        margin: 0;
        position: inherit;
        background-color: inherit;

        li {
          border-radius: 0;
        }

        span {
          margin: 0;
        }
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.invisible {
  visibility: hidden;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.translate-y-4 {
  transform: translateY(1rem);
}

.translate-y-0 {
  transform: translateY(0);
}

.transition-all {
  transition: all 200ms ease-in-out;
}
